<template>
	<div class="user-detail">
		<div class="row margin-0">
			<div class="col-md-12 pad-0">
				<div class="parent">
					<div class="parent-head">
						<ul>
							<li @click="tab='About'" :class="{ active: tab === 'About' }">
								About
							</li>
              <li @click="tab='Products'" :class="{ active: tab === 'Products' }">
								Products
							</li>
              <!-- <li @click="tab='Subscriptions'" :class="{ active: tab === 'Subscriptions' }">
								Subscriptions
							</li> -->
              <li @click="tab='Transactions'" :class="{ active: tab === 'Transactions' }">
								Transactions
							</li>
              <!-- <li @click="tab='Coupons'" :class="{ active: tab === 'Coupons' }">
								Coupons
							</li> -->
              <li @click="tab='Devices'" :class="{ active: tab === 'Devices' }">
								Devices
							</li>
						</ul>
					</div>
					<div class="parent-body">
            <div class="row margin-0">
              <div class="col-12 pad-0">
                <div class="row margin-0" v-if="tab==='About'">
                  <div class="col-12 pad-0">
                    <div class="row margin-0" v-if="tabAction==='EDIT'">
                      <div class="col-5 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>Full Name</b></label>
                          <input
                            type="text"
                            v-model="userPayload.name"
                            class="form-control form-control-sm"
                            placeholder="Full Name"
                          />
                          <span class="error" v-if="error && error.name">{{
                            error.name
                          }}</span>
                        </div>
                      </div>
                      <div class="col-4 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>Email</b></label>
                          <input
                            type="text"
                            v-model="userPayload.email"
                            class="form-control form-control-sm"
                            placeholder="Email Address"
                          />
                          <span class="error" v-if="error && error.email">{{
                            error.email
                          }}</span>
                        </div>
                      </div>
                      <div class="col-3 pad-left-0">
                        <div class="info-block">
                          <label for="">Mobile</label>
                          <p>{{user.mobile}}</p>
                        </div>
                      </div>
                      <div class="col-5 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>Path</b></label>
                          <input
                            type="text"
                            v-model="userPayload.path"
                            class="form-control form-control-sm"
                            placeholder="Full Path"
                          />
                          <span class="error" v-if="error && error.path">{{
                            error.path
                          }}</span>
                        </div>
                      </div>
                      <div class="col-3 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>User Type</b></label><br>
                          <select name="" id="" v-model="userPayload.user_type" class="form-control form-control-sm">
                            <option value="CUSTOMER">CUSTOMER</option>
                            <option value="BUSINESS">BUSINESS</option>
                          </select>
                          <span class="error" v-if="error && error.user_type">{{
                            error.user_type
                          }}</span>
                        </div>
                      </div>
                      <div class="col-2 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>Gender</b></label><br>
                          <select name="" id="" v-model="userPayload.gender" class="form-control form-control-sm">
                            <option value="male">MALE</option>
                            <option value="female">FEMALE</option>
                          </select>
                          <span class="error" v-if="error && error.gender">{{
                            error.gender
                          }}</span>
                        </div>
                      </div>
                      <div class="col-2 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>Status</b></label><br>
                          <select name="" id="" v-model="userPayload.status" class="form-control form-control-sm">
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                            <option value="HOLD">HOLD</option>
                            <option value="SUSPENDED">SUSPENDED</option>
                            <option value="DEATIVATED">DEATIVATED</option>
                          </select>
                          <span class="error" v-if="error && error.status">{{
                            error.status
                          }}</span>
                        </div>
                      </div>
                      <div class="col-2 pad-left-0">
                        <div class="form-group">
                          <label for=""><b>Permissions</b></label><br>
                          <select multiple name="" id="" v-model="userPayload.permissions" class="form-control form-control-sm">
                            <option value="CREATE">CREATE</option>
                            <option value="UPDATE">UPDATE</option>
                          </select>
                          <span class="error" v-if="error && error.permissions">{{
                            error.permissions
                          }}</span>
                        </div>
                      </div>
                      <div class="col-10 pad-0 text-center">
                        <button
                          @click="updateUserData()"
                          class="btn btn-success btn-sm margin-right-10"
                        >
                          Update
                        </button>
                        <button @click="userPayload={...user}" class="btn btn-outline-danger btn-sm margin-right-10">
                          Reset
                        </button>
                        <button @click="tabAction='VIEW'" class="btn btn-light btn-sm">
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div class="row margin-0" v-else>
                      <div class="col-10 pad-0">
                        <div class="row margin-0">
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Full Name</label>
                              <p>{{user.name}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Mobile</label>
                              <p>{{user.mobile}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Email Address</label>
                              <p>{{user.email || '-'}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Wallet</label>
                              <p>{{user.wallet}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">User Type</label>
                              <p>{{user.user_type}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Gender</label>
                              <p>{{user.gender || '-'}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Permissions</label>
                              <p>{{user.permissions}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Location</label>
                              <p>{{user.address?user.address.name||user.address:'-'}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Path</label>
                              <p>{{user.path}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <div class="info-block">
                              <label for="">Joined On</label>
                              <p>{{$filters.fullDateTime(user.created_on)}}</p>
                            </div>
                          </div>
                          <div class="col-4 pad-left-0">
                            <button @click="tabAction='EDIT'" class="btn btn-outline-secondary btn-sm">
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 pad-0">
                        <img :src="user && user.image?user.image:'/user.webp'" style="max-width:180px;margin-bottom:10px;filter:brightness(0.7);" alt=""> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Subscriptions'">
                  <div class="col-12 pad-0">
                    <Subscriptions :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Transactions'">
                  <div class="col-12 pad-0">
                    <Transactions :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Products'">
                  <div class="col-12 pad-0">
                    <Products :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Enrollments'">
                  <div class="col-12 pad-0">
                    <Enrollments :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Coupons'">
                  <div class="col-12 pad-0">
                    <Coupons :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Devices'">
                  <div class="col-12 pad-0">
                    <Devices :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='SkillSet'">
                  <div class="col-12 pad-0">
                    <template v-if="skillsList && skillsList[0] && skillsList[0].courses_detail">
                      <ul class="list-inline">
                        <template v-for="(item, idx) in skillsList[0].courses_detail" :key="idx">
                          <li class="btn btn-outline-danger btn-sm margin-5">
                            {{item.parent_detail?item.parent_detail.topic+' - ':''}} {{item.title||'-'}}
                          </li>
                        </template>
                      </ul>
                    </template>
                    <template v-else>
                      Loading...
                    </template>
                  </div>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Transactions from '../Transactions.vue'
import Subscriptions from '../Subscriptions.vue'
import Products from '../Products.vue'
import Enrollments from '../Enrollments.vue'
import Coupons from '../Coupons.vue'
import Devices from '../Devices.vue'
export default {
  components: {
    Transactions,
    Subscriptions,
    Products,
    Enrollments,
    Coupons,
    Devices
  },
	props: ["user"],
	data() {
		return {
      tab: "About",
      tabAction: 'VIEW',
      addressList: null,
      skillsList: null,
      userPayload: null,
			msg: null,
      status: null,
		};
	},
	created() {
    this.userPayload = {
      ...this.user
    };
    // this.getAddressList();
    this.status = this.user.status;
  },
  watch: {
    'user': function(newVal, oldVal) {
      this.status = this.user.status;
    }
  },
	methods: {
    updateUserData() {
      const that = this;
      that.$cs.user
        .update({
            resource: `${that.$cs.user.api}${that.user.id}/`,
            data: that.userPayload
        })
        .then(
          data => {
            that.$alert("Success", "User updated sucessfully");
            that.$emit('updated');
            that.tabAction = 'VIEW';
          },
          () => {
            that.$alert("Error", "Failed to update user.");
            console.log("error");
          }
        );
    },
    updateUserStatus() {
      const that = this;
      that.$cs.user
        .update({
            resource: `${that.$cs.user.api}${that.user.id}/status/`,
            data: {
              status: this.status
            }
        })
        .then(
          data => {
            that.$emit('updated');
          },
          () => {
            console.log("error");
          }
        );
    },
    getSkillSet() {
      const that = this;
      that.$cs.user
        .list({
            resource: `${that.$cs.user.api}${this.user.id}/profiles/`,
        })
        .then(
          data => {
            that.skillsList = data.data;
          },
          () => {
            console.log("error");
          }
        );
    }
  },
};
</script>
<style scoped lang="stylus">
.address-list
  list-style none
  padding 0px
  margin 0px
  display flow-root
  li
    float left
    width 31.2%
    margin 1%
    border 1px solid #dddddd
    padding: 14px;
    background-color: antiquewhite;
    border-radius 4px
    p
      margin-bottom 5px
      b
        font-weight bold
</style>
